@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.content {
  width: 100%;
  position: relative;
  padding: 24px;
  background: var(--matterColorLight);
  border-radius: 8px;
  margin-bottom: 8px;

  & > form {
    width: 100%;

    @media (--viewportLarge) {
      max-width: 650px;
    }
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.dashboardPage {
  display: flex;
  background: var(--matterColorBright);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.01em;
  color: #1a1d1f;

  @media (--viewportMedium) {
    padding: 80px 0 0 200px;
  }
  @media (--viewportLarge) {
    padding: 80px 0 0 250px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 80px 0 0 280px;
  }

  & .sideBar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 14px;
    background: #ffffff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 200px;
    @media (--viewportLarge) {
      width: 250px;
    }

    @media (--viewportLargeWithPaddings) {
      width: 280px;
      padding: 14px 24px 24px 24px;
    }

    & .logoLink {
      margin-bottom: 48px;

      & > img {
        width: 150px;
        @media (--viewportLargeWithPaddings) {
          width: 200px;
        }
      }
    }
  }

  & .mainContent {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    width: 100%;
    padding: 16px;

    @media (--viewportMedium) {
      padding: 24px;
    }
    @media (--viewportLarge) {
      padding: 30px;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 40px;
    }

    & .header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 20;
      display: flex;
      align-items: center;
      background: #fcfcfc;
      box-shadow: inset 1px 0px 0px #fbfbfb, inset 0 -1px 0px #efefef;

      @media (--viewportMedium) {
        left: 200px;
      }
      @media (--viewportLarge) {
        left: 250px;
      }

      @media (--viewportLargeWithPaddings) {
        left: 278px;
      }
    }

    & .pageContent {
      padding-top: 70px;

      @media (--viewportMedium) {
        padding-top: 0;
      }

      & .fixedWidthLayout {
        /* max-width: 80%; */
        margin: 0 auto;
        width: 100%;

        & .payoutsContent {
          & > h1 {
            font-size: 24px;
            line-height: 1.2;
            letter-spacing: -0.02em;
            font-weight: 600;
            margin: 0 0 24px 0;

            @media (--viewportMedium) {
              font-size: 30px;
            }
          }

          & .currentBalanceSec {
            position: relative;
            padding: 24px;
            background: #fcfcfc;
            border-radius: 8px;
            margin-bottom: 8px;

            & .title {
              position: relative;
              padding-left: 32px;
              font-size: 20px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: -0.02em;
              display: flex;
              align-items: center;
              min-height: 40px;
              margin-bottom: 32px;

              & .mark {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 16px;
                height: 32px;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                border-radius: 4px;
                background: #c6488c;
              }
            }

            & .content {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-gap: 50px;

              @media (--viewportLargeWithPaddings) {
                grid-template-columns: repeat(3, 1fr);
              }

              & .block {
                display: flex;
                align-items: center;
                width: 100%;

                &:last-child {
                  justify-content: flex-start;

                  @media (--viewportLargeWithPaddings) {
                    justify-content: flex-end;
                  }
                }

                & .blockIcon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  width: 48px;
                  height: 48px;
                  margin-right: 24px;
                  border-radius: 50%;

                  &.balanceIcon {
                    background-color: #b5e4ca;
                  }

                  &.withdrawalIcon {
                    background-color: #cabdff;
                  }

                  & > svg {
                    fill: none;
                    width: 24px;
                    height: 24px;
                  }
                }

                & .blockDetails {
                  & > p {
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: 8px;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 1.23077;
                    color: #6f767e;
                  }

                  & > h2 {
                    margin: 0px;
                    font-size: 38px;
                    font-weight: 600;
                    line-height: 1;
                    letter-spacing: -0.03em;

                    @media (--viewportLargeWithPaddings) {
                      font-size: 48px;
                    }
                  }
                }

                & .withdrawalBtn {
                  background-color: var(--marketplaceColor);
                  color: var(--matterColorLight);
                  border: solid 1px var(--marketplaceColor);
                  padding: 10px 20px;
                  font-size: 16px;
                  font-weight: 400;
                  transition: all ease 0.5s;

                  &:hover {
                    background-color: var(--matterColorDark);
                    color: var(--matterColorLight);
                    border: solid 1px var(--matterColorDark);
                    cursor: pointer;
                  }

                  &:disabled {
                    background-color: #dedede;
                    color: var(--matterColorLight);
                    border: solid 1px #dedede;
                    cursor: not-allowed;
                  }
                }
              }
            }
          }

          & .payoutHistory {
            position: relative;
            padding: 24px;
            background: #fcfcfc;
            border-radius: 8px;

            & .title {
              position: relative;
              padding-left: 32px;
              font-size: 20px;
              font-weight: 600;
              line-height: 1.6;
              letter-spacing: -0.02em;
              display: flex;
              align-items: center;
              min-height: 40px;
              margin-bottom: 32px;

              & .mark {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 16px;
                height: 32px;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                border-radius: 4px;
                background: #c6488c;
              }
            }

            & .payout__table {
              display: table;
              width: 100%;

              & .payout__row {
                display: table-row;

                &:nth-child(2n) {
                  & .payout__col {
                    background: rgba(239, 239, 239, 0.35);
                  }
                }

                &:first-child {
                  & .payout__col {
                    padding-top: 12px;
                    padding-bottom: 16px;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 1.33333;
                    font-weight: 500;
                    color: #6f767e;
                  }
                }

                & .payout__col {
                  display: table-cell;
                  padding: 10px 16px;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 1.71429;

                  &:first-child {
                    padding-left: 24px;
                    color: #6f767e;
                  }

                  & .payout__status {
                    font-size: 14px;
                    line-height: 28px;
                    font-weight: 600;
                    color: #1a1d1f;
                    display: inline-block;
                    padding: 0 8px;
                    border-radius: 6px;

                    &.status_green_dark {
                      background: #b5e4ca;
                    }

                    &.status_blue {
                      background: #c6488c;
                    }

                    &.status_purple {
                      background: #cabdff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  padding: 10%;
}
