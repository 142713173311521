.root {
}

/* Shared styles for infoText variants */
.infoText {
  composes: marketplaceH4FontStyles from global;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 14px;
    margin-bottom: 1px;
  }
}

.contactDetailsSection {
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    padding-top: 0;
  }
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.emailVerified {
  composes: infoText;
  color: var(--successColor);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  composes: infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  composes: infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }

  & > button {
    font-size: 16px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.error {
  composes: marketplaceH4FontStyles from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.shippingOptions {
  display: flex;
  justify-content: space-between;

  & > input {
    width: fit-content;
    accent-color: #c6488c;
  }

  & > label {
    cursor: pointer;
    width: initial;
    position: relative;
    top: 10px;
    right: 6px;
  }

  @media (max-width: 991px) {
    display: block;
  }

  & > span {
    width: calc(100% / 2);
  }
}

.formRow {
  display: flex;
  gap: 20px;

  & .formFld {
    margin-bottom: 24px;
    width: 100%;
  }
}

.shippingPreference {
  margin-top: 0px;
  margin-bottom: 10px;
}

.shippingPayment {
  margin: 20px 0 0;

  & .actions {
    display: flex;
    align-items: center;

    & > span {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  & > label {
    & > svg {
      margin-left: 5px;
    }
  }
}

.packageSize {
  & > svg {
    margin-left: 5px;
  }
}

.thresholdAmount {
  margin: 20px 0 0;
}

.phoneNumber {
  width: 100%;
  margin: 0px;

  & > input {
    background-color: var(--matterColorLight);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.tutulistOptionBlock {
  background-color: var(--matterColorBright);
  padding: 20px;
  margin: 10px 0;
  border: solid 1px var(--matterColorNegative);
  border-radius: 10px;
}

.hereText {
  cursor: pointer;
  margin-left: 4px;
  color: #c6488c;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.showSetDefaultWeight {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;

  & input {
    border-radius: 4px;
  }

  & select {
    border-radius: 4px;
    padding: 0px 12px;
  }
}

.saveButton {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  & button {
    width: 140px;
  }
}

.nonUSOptions {
  display: flex;
  gap: 14px;

  & .nonUSOptionsHeading {
    margin-top: 8px;
    font-weight: 600;
  }
}
